exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-property-js": () => import("./../../../src/pages/property.js" /* webpackChunkName: "component---src-pages-property-js" */),
  "component---src-pages-reception-hall-js": () => import("./../../../src/pages/reception-hall.js" /* webpackChunkName: "component---src-pages-reception-hall-js" */),
  "component---src-pages-red-door-villa-js": () => import("./../../../src/pages/red-door-villa.js" /* webpackChunkName: "component---src-pages-red-door-villa-js" */),
  "component---src-pages-spaces-js": () => import("./../../../src/pages/spaces.js" /* webpackChunkName: "component---src-pages-spaces-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-weddings-js": () => import("./../../../src/pages/weddings.js" /* webpackChunkName: "component---src-pages-weddings-js" */),
  "component---src-pages-white-door-villa-js": () => import("./../../../src/pages/white-door-villa.js" /* webpackChunkName: "component---src-pages-white-door-villa-js" */)
}

